import PodcastArchiveHeader from "~/features/podcast/PodcastArchiveHeader";
import PodcastList from "~/features/podcast/PodcastList";
import DefaultLayout from "~ui/DefaultLayout/DefaultLayout";
import Pagination from "~ui/Pagination/Pagination";

export default function PodcastSeries({
  pagination = {},
  results = [],
  layout = "",
  filters: { primary = "", active = {} } = {},
  archiveInfo = {},
}) {
  // If we're on the filter page, use the filter label and description
  // Otherwise, use the page-level label and description
  const pageHeader = active[primary] || archiveInfo;
  const { title, dek, slug, rss_url, spotify_url, apple_url, pocketcasts_url, youtube_url } =
    pageHeader;

  return (
    <DefaultLayout>
      <PodcastArchiveHeader
        title={title}
        slug={slug}
        description={dek}
        rss_url={rss_url}
        spotify_url={spotify_url}
        apple_url={apple_url}
        pocketcasts_url={pocketcasts_url}
        youtube_url={youtube_url}
      />
      <PodcastList results={results} lazyLoadAfter={3} />
      <Pagination pagination={pagination} />
    </DefaultLayout>
  );
}
